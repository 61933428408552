const ComponentHeadline = ({ key, data }) => (
  <div
    key={`component_${key}`}
    className="component component-headline"
    layout={data.layout}
  >
    <h2>{data.headline}</h2>
  </div>
);

export default ComponentHeadline;
