import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchContentBySlug from "../api";

const Home = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const routeSlug = window.location.pathname.split("/").pop();
    const type = "pages";
    fetchContentBySlug(type, routeSlug)
      .then((data) => setContent(data))
      .catch(() => navigate("/404"));
  }, [navigate]);

  return (
    <div className="component">
      <h2>Use Cases</h2>
      {content ? (
        content.map((item) => (
          <div key={item.id}>
            <h3>{item.title.rendered}</h3>
            <p dangerouslySetInnerHTML={{ __html: item.content.rendered }}></p>
          </div>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Home;
