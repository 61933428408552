import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Benefits from "./pages/Benefits";
import UseCases from "./pages/UseCases";
import Contact from "./pages/Contact";
import Components from "./pages/Components";
import NotFound from "./pages/NotFound";
import ProductDetail from "./pages/ProductDetail";
import NavHeader from "./components/nav-header";
import NavFooter from "./components/nav-footer";
import fetchOptions from "./api-options";

const App = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOptions()
      .then((data) => {
        setOptions(data);
        setLoading(false);
      })
      .catch(() => {
        console.warn("no navigation available");
        setLoading(false);
      });
  }, []);

  return (
    <Router>
      <>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <NavHeader options={options.header_links} />
            <article className="main" role="main">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products" element={<Products />} />
                <Route path="/services" element={<Services />} />
                <Route path="/benefits" element={<Benefits />} />
                <Route path="/use-cases" element={<UseCases />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/components" element={<Components />} />
                <Route path="/product/*" element={<ProductDetail />} />
                <Route path="*" element={<Components />} />
              </Routes>
            </article>
            <NavFooter options={options} />
          </>
        )}
      </>
    </Router>
  );
};

export default App;
