import { React } from "react";
import { NavLink } from "react-router-dom";

const NavFooter = ({ options }) => {
  function NavLinks(url, title) {
    const itemUrl = new URL(url);
    const relativeUrl = itemUrl.pathname;
    return (
      <NavLink to={relativeUrl} className="nav-link">
        {title}
      </NavLink>
    );
  }

  const contact = options.footer_block_contact;
  const block2 = options.footer_block_2;
  const block3 = options.footer_block_3;
  const block4 = options.footer_block_4;

  return (
    <footer>
      <span className="logo">Metrilus</span>
      <section className="footer-links__contact">
        <h3>{contact.footer_block_headline}</h3>
        <address>
          {contact.street} <br />
          {contact.plz} {contact.city} <br />
          {contact.country} <br />
          <a href={`tel:${contact.telephone}`}>{contact.telephone}</a> <br />
          {NavLinks(contact.press.url, contact.press.title)}
        </address>
      </section>
      <section className="footer-links__block-2">
        <h3>{block2.footer_block_headline}</h3>
        <ul>
          {block2.links.map((item, index) => (
            <li key={index}>{NavLinks(item.link.url, item.link.title)}</li>
          ))}
        </ul>
      </section>
      <section className="footer-links__block-3">
        <h3>{block3.footer_block_headline}</h3>
        <ul>
          {block3.links.map((item, index) => (
            <li key={index}>{NavLinks(item.link.url, item.link.title)}</li>
          ))}
        </ul>
      </section>
      <section className="footer-links__block-4">
        <h3>{block4.footer_block_headline}</h3>
        <ul>
          {block4.links.map((item, index) => (
            <li key={index}>{NavLinks(item.link.url, item.link.title)}</li>
          ))}
        </ul>
      </section>
      <ul className="footer-links__social">
        <li>
          <a href={contact.social_link_1.url}>{contact.social_link_1.title}</a>
        </li>
        <li>
          <a href={contact.social_link_2.url}>{contact.social_link_2.title}</a>
        </li>
        <li>
          <a href={contact.social_link_3.url}>{contact.social_link_3.title}</a>
        </li>
      </ul>
      <div className="imprint">Metrilus GmbH</div>
    </footer>
  );
};

export default NavFooter;
