// Todo: build this component
const ComponentDescriptionList = ({ key, data }) => (
  <div
    key={`component_${key}`}
    className="component component-quote"
    layout={data.layout}
  >
    <div>
      <blockquote>"{data.quote_text}"</blockquote>
      <p className="component-quote__author">{data.line_copy}</p>
    </div>
  </div>
);

export default ComponentDescriptionList;
