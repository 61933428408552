const ComponentTeaser = ({ key, data }) => (
  <section key={`component_${key}`} className="component component-teasers">
    {data.teaser.map((item, index) => (
      <div
        key={`teaser_${index}`}
        className="component component-teaser"
        layout={item.teaser_layout}
        style={{
          "--bg-teaser-start": item.background_color_start,
          "--bg-teaser-end": item.background_color_end,
        }}
      >
        <h3>{item.headline}</h3>
        <p>{item.paragraph}</p>
        <img src={item.teaser_image.url} alt={item.teaser_image.alt} />
        <a href={item.teaser_cta_link.url} className="link--cta">
          {item.teaser_cta_label
            ? item.teaser_cta_label
            : item.teaser_cta_link.title}
        </a>
      </div>
    ))}
  </section>
);

export default ComponentTeaser;
