// Todo: build this component
const ComponentTable = ({ key, data }) => (
  <div
    key={`component_${key}`}
    className="component component-quote"
    layout={data.layout}
  >
    <div>
      <p className="component-quote__author">Todo: build Table component</p>
    </div>
  </div>
);

export default ComponentTable;
