const ComponentParagraphImage = ({ key, data }) => (
  <div
    key={`component_${key}`}
    className="component component-paragraph-image"
    layout={data.layout}
    reversed={data.reversed_order}
  >
    <div dangerouslySetInnerHTML={{ __html: data.paragraph }} />
    <div>
      <figure>
        <img src={data.image.url} alt={data.image.url} />
        {data.image.caption ? (
          <figcaption>{data.image.caption}</figcaption>
        ) : (
          ""
        )}
      </figure>
    </div>
  </div>
);

export default ComponentParagraphImage;
