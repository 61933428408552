const ComponentParagraph = ({ key, data }) => (
  <div
    key={`component_${key}`}
    className="component component-paragraph"
    layout={data.layout}
    align={data.align}
    dangerouslySetInnerHTML={{ __html: data.paragraph }}
  />
);

export default ComponentParagraph;
