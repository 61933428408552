import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchContentBySlug from "../api";

const ProductDetail = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const routeSlug = window.location.pathname.split("/").pop();
    const type = "product";
    fetchContentBySlug(type, routeSlug)
      .then((data) => setContent(data))
      .catch(() => navigate("/404"));
  }, [navigate]);

  //   const productDetails = () => {
  //     return `<div class="component component-desc-list">
  //     <dl>
  //       <dt>Advanced Calibration Routines</dt>
  //       <dd>S 110 | 120 [DWS System]</dd>
  //       <dd>L 230 | 235 [Pallet Dimensioner]</dd>

  //       <dt>Advanced Input Controls</dt>
  //       <dd>improve user experience</dd>
  //       <dd>
  //         various recurring tasks without using a keyboard or mouse
  //       </dd>

  //       <dt>Automatic Placement Verification</dt>
  //       <dd>built-in automatic mode of operation</dd>
  //       <dd>smart stabilization</dd>
  //       <dd>
  //         no additional process time is needed after an ID has been
  //         scanned
  //       </dd>
  //     </dl>
  //   </div>`;
  //   };

  return (
    <div className="component">
      {content ? (
        content.map((item) => (
          <div key={item.id}>
            <h2>{item.acf.product_name}</h2>
            {/* <>
              <h3>Highlights</h3>
              {item.acf.product_highlights.map((item) => (
                <p>{item.highlight}</p>
              ))}
            </> */}
            {item.acf.product_data_section.map((productData) => (
              <>
                <h3>{productData.specification_headline}</h3>
                <div class="component component-desc-list">
                  <dl>
                    {productData.specifications.map((productSpecs) => (
                      <>
                        <dt>{productSpecs.key}</dt>
                        <>
                          {productSpecs.values.map((item) => (
                            <dd>{item.value}</dd>
                          ))}
                        </>
                      </>
                    ))}
                  </dl>
                </div>
              </>
            ))}
            {item.acf.product_gallery.map((image) => (
              <>
                <h3>Gallery</h3>
                <figure class="component component-figure" width-100>
                  <img
                    src={image}
                    alt="describing what's happening in the image"
                  />
                  <figcaption>Caption of the image</figcaption>
                </figure>
              </>
            ))}
          </div>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ProductDetail;
