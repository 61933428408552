const ComponentHero = ({ key, data }) => (
  <div
    key={`component_${key}`}
    className="component component-hero"
    style={{ backgroundImage: `url('${data.hero_image.url}')` }}
  >
    <h1>{data.hero_headline}</h1>
    <div>
      <p>{data.hero_paragraph}</p>
      <a href={data.hero_cta_link.url} className="link--cta">
        {data.hero_cta_label ? data.hero_cta_label : data.hero_cta_link.title}
      </a>
    </div>
  </div>
);

export default ComponentHero;
