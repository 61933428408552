const fetchContentBySlug = async (type, slug) => {
  try {
    const response = await fetch(
      `https://metrilus.testbu.de/wp-json/wp/v2/${type}?slug=${slug}&acf_format=standard`
    );
    if (!response.ok) {
      throw new Error("Content not found");
    }
    const data = await response.json();
    console.log("rest api page", data);
    return data;
  } catch (error) {
    console.error("Error fetching content:", error);
    throw error;
  }
};

export default fetchContentBySlug;
