import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchContentBySlug from "../api";

const Home = () => {
  const [content, setContent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const routeSlug = window.location.pathname.split("/").pop();
    const type = "pages";
    fetchContentBySlug(type, routeSlug)
      .then((data) => setContent(data))
      .catch(() => navigate("/404"));
  }, [navigate]);

  return (
    <>
      <div className="component">
        <h2>Benefits</h2>
      </div>
      {content ? (
        content.map((item) => (
          <>
            {/* <p dangerouslySetInnerHTML={{ __html: item.content.rendered }}></p> */}
            <section className="component component-teasers">
              {item.acf.components.map((component) => (
                <div className="component component-teaser" size="medium">
                  <h3>{component.headline}</h3>
                  <p>{component.excerpt}</p>
                  <img
                    src={component.image}
                    alt="describing what's happening in the image"
                  />
                  <a href="#" className="link--cta">
                    Learn more about us
                  </a>
                </div>
              ))}
            </section>
          </>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default Home;
