import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchContentBySlug from "../api";
import ComponentHeadline from "../components/headline.js";
import ComponentIntro from "../components/intro.js";
import ComponentParagraph from "../components/paragraph.js";
import ComponentParagraphImage from "../components/paragraph-image.js";
import ComponentQuote from "../components/quote.js";
import ComponentMedia from "../components/media.js";
import ComponentHero from "../components/hero.js";
import ComponentTable from "../components/table.js";
import ComponentTeaser from "../components/teaser.js";
import ComponentDescriptionList from "../components/description-list.js";

const Components = () => {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const routeSlug = window.location.pathname.split("/").pop();
    const type = "pages";
    fetchContentBySlug(type, routeSlug)
      .then((data) => {
        setContent(data[0].acf.components);
        setLoading(false);
      })
      .catch(() => {
        navigate("/404");
        setLoading(false);
      });
  }, [navigate]);

  return (
    <div className="component">
      {loading ? (
        <div>Loading...</div>
      ) : (
        content.map((component, index) => {
          switch (component.acf_fc_layout) {
            case "intro":
              return ComponentIntro({
                data: component,
                key: index,
              });
            case "headline":
              return ComponentHeadline({
                data: component,
                key: index,
              });
            case "paragraph":
              return ComponentParagraph({
                data: component,
                key: index,
              });
            case "paragraph_with_image":
              return ComponentParagraphImage({
                data: component,
                key: index,
              });
            case "quote":
              return ComponentQuote({
                data: component,
                key: index,
              });
            case "media":
              return ComponentMedia({
                data: component,
                key: index,
              });
            case "hero":
              return ComponentHero({
                data: component,
                key: index,
              });
            case "table":
              return ComponentTable({
                data: component,
                key: index,
              });
            case "teaser_block":
              return ComponentTeaser({
                data: component,
                key: index,
              });
            case "description_list":
              return ComponentDescriptionList({
                data: component,
                key: index,
              });
            default:
              return null;
          }
        })
      )}
    </div>
  );
};

export default Components;
