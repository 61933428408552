const fetchOptions = async () => {
  try {
    const response = await fetch(
      "https://metrilus.testbu.de/wp-json/wp/v2/options/all"
    );
    if (!response.ok) {
      throw new Error("Content not found");
    }
    const data = await response.json();
    console.log("rest api options", data);
    return data;
  } catch (error) {
    console.error("Error fetching content:", error);
    throw error;
  }
};

export default fetchOptions;
