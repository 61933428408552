const ComponentMedia = ({ key, data }) =>
  data.image && (
    <figure
      key={`component_${key}`}
      className="component component-figure"
      layout={data.layout}
      align={data.align}
    >
      <img src={data.image.url} alt={data.image.alt} />
      {data.image.caption ? <figcaption>{data.image.caption}</figcaption> : ""}
    </figure>
  );

// data.video && <p>Video</p>;

export default ComponentMedia;
